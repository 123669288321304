.cookie-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    padding: calc(2*var(--text-padding)) var(--text-padding);
    height: 200px;
    width: 100vw;
    bottom: 0;
    right: 0;
    left: 0;
    background: var(--main-dark-color);
    color: var(--main-light-color);
    font-size: 1rem;
    font-family: "Lato", sans-serif;    
  }
  
  .cookie-bar:hover {
    background: var(--hover-color);
    color: black;
    transition: 1s ease-out;
  }
      
  .close-cb {
    margin-left: 10px;
    padding: var(--text-padding);
    border: 5px solid var(--secondary-color);
    background: radial-gradient(circle, rgb(117, 173, 34), green);
    color: var(--main-light-color);
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 2.5px 2.5px 5px 5px greenyellow;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
  }
  
  
  
  .close-cb:hover {
    background: radial-gradient(circle, rgb(177, 224, 107), rgb(33, 233, 33));
    color: black;
    text-align: center;
    transition: 1s ease-out;
  }
  
  .cookie-bar .message {
    font-family: 'Rokkitt', serif;     
  }
  
  .cookie-bar .mobile {
    display: none; 
    font-family: 'Rokkitt', serif;  
  }
  
  a {
    text-decoration: none;
  }
  
  
  /*No clicking on the X visable the cookie bar, clicked on the X no visible.*/
  
  .checkbox-cb {
      display: none;    
  }
  
  .checkbox-cb:checked + .cookie-bar {
        transform: translateY(250px);
  }  
  
  .cookie-bar a {
      color: var(--secondary-color);
      
  }
  
  .cookie-bar a:hover {
    color: red;
    transition: 1s ease-out;
  }
  
  /*media queries for cookies*/

  @media screen and (min-width: 100px) {

    .menu-titles {
      font-size: .9rem;
    }
    .close-cb {
        font-size: .7rem;
        
    }
}

  @media screen and (min-width: 320px) {
    .cookie-bar {
      text-align: center;
      font-size: 1.1rem;
    }
  
    .close-cb {
      font-size: 1.1rem;
    }
  }
  
  @media screen and (min-width: 375px) {
    .cookie-bar {
      font-size: 1.2rem;
    }
  
    .close-cb {
      font-size: 1.2rem;
    }
  }
  
  @media screen and (min-width: 568px){
    .cookie-bar {
     font-size: 1.5rem;
    }
  
    .close-cb {
      font-size: 1.2rem;
    }
   
  }
  
  
  @media screen and (max-width: 1024px) {
    .cookie-bar  .mobile {
      display: inline-block;
    }
  
    .close-cb {
      right: 0px;
    }
  
    .cookie-bar .message {
      display: none;
    }
  }