/*This file includes the elements of the footer such as
*contact: phone, email
*legal information
*/

*,
::before,
::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

/*The footer content aligned with flexbox system where footer class is the flex container*/


.footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: var(--text-padding) var(--text-padding) calc(2*var(--text-padding)) var(--text-padding);
}

.contact {
    text-align: center;
}

.copyright {
    text-align: center;
}

.button-wo-design {
    all: unset;
    cursor: pointer;
}

.gdpr {
    display: flex;
    justify-content: center;
    align-items: center;
    
}


.gdpr a, .cookies a, .terms-and-conditions a, .contact a {
    text-decoration: none;
    display: inline-block;    
    color: var(--main-light-color);   
}

.button-wo-design, .gdpr a, .cookies a, .terms-and-conditions a {
    color: var(--secondary-color);
}


.legal-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
}

.footer a, .button-wo-design {
    margin-right: 20px;
    transition: 0.5s linear;
}


/*hover effect to emphasize links*/

.footer a:hover, .button-wo-design:hover {
    color: var(--hover-color);
    transform: scale(1.2);
    transition: 0.5s linear;
}