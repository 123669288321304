*,
::before,
::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}


/*variables*/
:root {
    --main-dark-color: rgba(0, 150, 190, 1);
    --logo-color: #b9bcbd;
    --main-light-color: #00ffff;
    --hover-color: rgba(230, 240, 100, 1);
    --secondary-color: #c0c0c0;
    --secondary-font-size: 15px;
    --text-padding: 15px;
    --photo-padding: 5px;
    --text-shadow: rgb(12, 12, 12) 1px 3px;
    --box-shadow: rgb(70, 68, 68) 1px 5px;
}

body {
    background-color: var(--main-dark-color);
    color: var(--main-light-color);
    font-family: 'Rokkitt', serif;
    overflow-x: hidden;
}

/*The below code retains the benefit content always below the menu line:*/
.place-holder-under-menuline {    
    background-color: var(--main-light-color);
    width: 98vw;
    height: 30px;
}

button {
    font-family: 'Rokkitt', serif;
}

h1 {
    font-family: 'Black Ops One', cursive;
    color: var(--main-light-color);
    font-weight: 400;
}

h2 {
    margin: calc(4*var(--text-padding)) 0 var(--text-padding) 0;
    font-family: 'Black Ops One', cursive;
    font-weight: 400;
    text-align: center;
}


ul {
    padding: var(--text-padding);
    list-style-type: circle;
}


li {
    margin-left: 20px;
}

.nested-list {    
    padding: 0 0 0 20px;
}

.nested-list li {
    font-size: .75em;
}


p {
    margin-bottom: var(--text-padding);
}

/*To synchronize the font size of button and li items:*/
.benefit-and-specs-list {
    font-size: 1rem;;
}