.benefits {
    grid-area: benefits;
    padding: 0 0 var(--text-padding) 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.benefits-list {
    list-style-type: none;
}

.benefit-img {
    grid-area: benefit-img;
    justify-self: right;
}


.benefit-photo {
    max-height: 89vh;
    width: 130px;
    padding: var(--text-padding);
}

