@media screen and (prefers-reduced-motion: no-preference) {
    html {
      scroll-behavior: smooth;
    }
}

/*smart phones portrait direction*/



/*Samsung Galaxy fold*/

@media screen and (min-width: 280px) {
    .main-title {
        font-size: 1.3rem;
    }

    .menu a, .language-selector {
        font-size: .9rem;
    }

    .menu a:hover {       
        transform: scale(1.1);        
    }

    .forsale, .disc {
        --width: 80px;
        font-size: .8rem;
        margin-left: -150px;
        margin-bottom: 0;
    }

    .intro-container {
        align-items: flex-end;
    }

    
    .modal {
        width: 90vw;
        font-size: 1rem;
    }

    
}

/*iPhone 5/SE*/

@media screen and (min-width: 320px) {
    .specification-photo {
        height: 110px;
        width: auto;
    }

    .menu a, .language-selector {
        font-size: 1rem;
    }
}

/*Samsung Galaxy*/

@media screen and (min-width: 360px) {
    .specification-photo {
        height: 120px;
        width: auto;
    }

    .menu a, .language-selector {
        font-size: 1.1rem;
    }
}

@media screen and (min-width: 375px) {
    .specification-photo {
        height: 130px;
        width: auto;
    }
    
    .video {
        height: 38vh;
        width: 100vw;
    }
   
}

@media screen and (min-width: 414px) {
    .video {
        height: 45vh;
        width: 100vw;
    }

    .specification-photo {
        height: 150px;
        width: auto;
    }
}

@media screen and (min-width: 540px) {
    .specification-photo {
        height: 200px;
        width: auto;
    }    

}

/*smart phones landscape direction*/

/*iPhone 5 SE*/

@media  screen and (min-width: 568px) {
    .main-page {
        grid-template-columns: 3fr 3fr;
    }

   

    .menu a:hover {
        transform: scale(1.2);
    }

    .hero-image {        
        height: 80vh;
    }
    
    .specification-photo {
        height: 220px;
        width: auto;
    }

    .video {
        height: 70vh;
        width: 100vw;
    }
    
    .recommendation-image {
        height: 70vh;
        width: 100%;
    } 

}

/* ipHone 6/7/8 */

@media screen and (min-width: 667px) {    
    body {
        font-size: 1.2rem;
    }

    .main-title {
        font-size: 1.4em;
    }

    .hero-image {
        height: 85vh;
        width: 100vw;
    }
    
    .specification-photo {
        height: 260px;
        width: auto;
    }



}

/* Samsung Galaxy Tab 712px*/

@media screen and (min-width: 712px) {

    .forsale, .disc {
        --width: 120px;
        font-size: 1.2rem;
        margin-left: 10px;
        margin-top: -200px;
    }

    .intro-container {
        align-items: flex-end;
    }

    .hero-image {
        align-items: center;        
    }

    
    .modal {
        width: 90vw;
        font-size: 1rem;
    }
}

/* iPhone 6/7/8 Plus */

@media screen and (max-width: 736px) {

    body, .accordion {
        font-size: 1.1rem;
    }
    .nested-list li {
        font-size: 1em;
    }       
}

@media screen and (min-width: 736px) {
    body {
        font-size: 1.4rem;
    }

    .main-title {
        font-size: 1.6em;
    }

    .place-holder-under-menuline {    
        height: 35px;
    }

    .menu a:hover {       
        transform: scale(1.2);        
    }

    .specification-photo {
        height: 290px;
        width: auto;
    }

    .forsale, .disc {
        --width: 120px;
        font-size: 1.2rem;
        margin-left: 10px;
        margin-top: -250px;
    }

    .intro-container {
        align-items: flex-end;
    }

    .hero-image {
        align-items: flex-start;      
    }

    
    .modal {
        width: 90vw;
        font-size: 1.1rem;
    }
}




/*iPad mini and iPad media query*/
@media  screen and (min-width: 768px) {

    .specification-photo {
        height: 300px;
        width: auto;
    }

    .menu-logo-line {
        height: 130px;
        grid-template-columns: 100px calc(98vw - 200px) 100px;
    }

  
    .logo-container {
        height: 100px;
        width: 100px;
        margin-right: 30px;
    }


    .main-title {
        font-size: 4rem;
    }
    
    .menu-cont {
        width: 80px;
        height: 80px;
        margin: 15px;
    }
    
    .menu-titles {
        top: 110px;
    }

    .menu a, .language-selector {
        font-size: 1.75rem;
    }

    .place-holder-under-menuline {    
        height: 100px;
    }

    
    .benefits, .specifications, .presentation, .recommendation {
        font-size: 2rem;
    }

    .footer {
        font-size: 1.5rem;
    }

    ul {
        padding: 20px;
    }
    
    .hero-image {        
        height: 60vh;
        align-items: center; 
        
    }

    .benefit-and-specs-list {
        font-size: 1.5rem;;
    }

    p {
        font-size: 1.5rem;
    }

    .forsale, .disc {
        --width: 120px;
        font-size: 1.2rem;
        margin-left: 10px;
        margin-top: -250px;
    }

    .intro-container {
        align-items: flex-end;
    }
    
    .modal {
        width: 90vw;
        font-size: 1.1rem;
    }
    
}

/*iPhone X media query and notebook 800*600*/

@media screen and (min-width: 800px) {

    .specification-photo {
        height: 310px;
        width: auto;
    }

    .menu-logo-line {
        height: 90px;
    }

    .logo-container {
        height: 80px;
        width: 80px;
    }

    .menu-titles {
        top: 90px;
    }
  
    
    .menu-cont {
        width: 50px;
        height: 50px;
        margin: 10px 15px 10px 30px;
    }

    .hero-image {        
        height: 85vh;        
    }

    .video {
        height: 82vh;
        width: 100vw;
    }

    h3 {
        font-size: .9em;
    }

    p {
        font-size: .9em;
    }

    .benefit-and-specs-list {
        font-size: .9em;;
    }

    .footer {
        font-size: 0.9em;
    }

    .forsale, .disc {
        --width: 130px;
        font-size: 1.5rem;
        margin-left: -10px;
        margin-bottom: 0;
      
    }

    .disc {
        --width: 135px;
    }

    .intro-container {
        align-items: flex-end;
    }

    .modal {
        width: 25vw;
        font-size: 1.2rem;
    }
}

/*iPad Air portrait 812px media query*/

@media screen and (min-width: 812px)  {
    
    .forsale, .disc {
        --width: 130px;
        font-size: 1.5rem;
        margin-left: -10px;
        margin-top: 150px;
      
    }

    .disc {
        --width: 135px;
    }

    .intro-container {
        align-items: center;
    }

    .modal {
        width: 40vw;
        font-size: 1.4rem;
    }
}

/*iPad pro portrait 1024px media query*/

@media  screen and (min-width: 1024px) {

    .specification-photo {
        height: 360px;
        width: auto;
    }
    .menu-logo-line {
        height: 110px;
        grid-template-columns: 100px calc(98vw - 200px) 100px;
    }

    .logo-container {
        height: 100px;
        width: 100px;
        margin-right: 40px;
    }


    .main-title {
        font-size: 4rem;
    }
    
    .menu-cont {
        width: 80px;
        height: 80px;
        margin: 15px 15px 15px 30px;
    }

    .menu-titles {
        font-size: 2.5rem;
        top: 100px;
    }

    .benefits, .specifications, .presentation, .recommendation {
        font-size: 2rem;
    }

    .specifications p, h4 {
        font-size: 1.5rem;        
    }

    .accordion {
        padding: var(--text-padding) calc(3*var(--text-padding));
    }

    .panel-wrap {
        padding: var(--text-padding) calc(2*var(--text-padding));
    }
    
   
    .footer {
        font-size: 1.2rem;
    }

    ul {
        padding: 20px;
    }
    
    .hero-image {        
        height: 100vh;
        
    }

    .video {
        height: 75vh;
        width: 100vw;
        margin-bottom: 20px;
    }    
    
}

/*19" screen 1280x1024*/

@media screen and (min-width: 1280px) {
    
    .specification-photo {
        height: 200px;
        width: auto;
    }

    .place-holder-under-menuline {
        height: 110px;
    }

    .menu-logo-line {
        height: 120px;
    }

    .logo-container {
        width: 110px;
        height: 110px;
    }

    .menu-titles {
        top: 110px;
    }
   

    .accordion {
        padding: var(--text-padding) calc(5*var(--text-padding));
    }

    .panel-text, .specification-img-wrap {
        padding: var(--text-padding) calc(2*var(--text-padding));
    }

    
    .panel-wrap {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        grid-template-areas: 
        "panel-text specification-img-wrap";
        padding: var(--text-padding) 0;
      }     
}

/*iPad pro landscape 1366x1024*/

@media screen and (min-width: 1366px) {
    .specification-photo {
        height: 240px;
        width: auto;
    }
}

/*14" laptop 1600x900*/

@media screen and (min-width: 1600px) {
    .specification-photo {
        height: 260px;
        width: auto;
    }
}

/*Large display media query above 1920px*/

@media screen and (min-width: 1920px) {

    .menu-logo-line {
        height: 140px;
        grid-template-columns: 100px calc(98vw - 200px) 100px;
    }

    .logo-container {
        width: 110px;
        height: 110px;
    }


    .main-title {
        font-size: 6rem;
    }
    
    .menu-cont {
        width: 80px;
        height: 80px;
        margin: 15px 15px 15px 30px;
    }

    .menu-titles {
        top: 120px;
    }

    .place-holder-under-menuline {
        height: 120px;
    }

    .hero-image {        
        height: 100vh;
        
    }

    h3 {
        font-size: 1.5em;
    }

    p {
        font-size: .8em;
        margin: 15px 0;
    }

    .benefit-and-specs-list {
        font-size: 1.1em;;
    }

    ul {
        padding: 20px;
    }

    div .footer {
        font-size: .9em;
    }

    .benefits, .specifications, .presentation, .recommendation, .footer {
        font-size: 2rem;
    } 

    .specification-photo {
        height: 350px;
        width: auto;
    }
  

    .video {
        height: 75vh;
        width: 100vw;
    }    

}
