*,
::before,
::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

/*Menu icon*/

.menu-icon-container {
    justify-self: center;
    align-self: center;
    grid-area: menu-icon-container;
}

.menu-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-area: menu-cont;    
    width: 50px;
    height: 50px;
    border: 1px solid var(--main-light-color);
    background-color: var(--main-dark-color);
    border-radius: 50px;
    cursor: pointer;
    transition: 0.5s linear;
}

   
/*menu icon hover*/
.menu-cont:hover {       
    transition: 0.5s linear;
    border: 1px solid var(--hover-color);		
}