*,
::before,
::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.imprint-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: var(--text-padding) 5px;   
}

.imprint-grid {
    width: 90vw;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto auto auto auto auto;
    grid-template-areas:
    "org org-content"
    "adress adress-content"
    "tel tel-content"
    "email email-content"
    "regnum regnum-content"
    "tax tax-content";
    column-gap: 5px;
    row-gap: 10px;  
}


.gdpr-wrap, .cookie-wrap, .terms-wrap, .imprint-wrap {   
    
    background-color: var(--main-light-color);
    color: var(--main-dark-color);
    font-family: 'Rokkitt', serif;
    width: 100vw;
    padding: 0 var(--text-padding); 
}

.gdpr-wrap, .imprint-wrap h1 {
    color: var(--main-dark-color);
}

h1 {
    text-align: center;
}

.short-text {
    text-align: center;
}

.long-text {
    text-align: justify;
}

ol, ul, li, .gdpr-wrap .long-text {
    margin: 20px;
    padding: 0;
    text-align: justify;
}

li p {
    padding: 0;
}

.important {
    font-weight: bold;
}

.alert {
    font-weight: bold;
    color: red;
}

@media screen and (min-width: 1080px) {
    .gdpr-wrap, .cookie-wrap, .terms-wrap, .imprint-wrap {
        padding: calc(5*var(--text-padding)); 
    }
}

